export default {
  locale: 'en-US',
  language: 'English',
  menu: {
    i18n: 'Internationalization'
  },
  system: {
    hmTableError: 'hm-table Component rendering error',
    pleaseEnter: 'please enter ',
    pleaseChoose: 'please choose',
    reUploadFile: 're-upload',
    uploadFile: 'Select a document',
    uploadFileTip: 'Support uploading png jpeg format pictures',
    dataLose: 'Missing parameters',
    editTip: 'Please select the row to edit',
    delTip: 'Please select or choose the row to be deleted',
    delConfirm: 'Are you sure you want to delete?',
    delConfirmContent: 'Are you sure you want to delete the selected <strong>{count}</strong> pieces of data?',
    delSuccess: 'successfully deleted',
    formCheckError: 'Form validation failed!',
    exportName: 'data sheet'
  },
  common: {
    search: 'search',
    reset: 'reset',
    unfold: 'unfold',
    packUp: 'pack up',
    submit: 'submit',
    submitSuccess: 'submit success',
    confirmTitle: 'Confirm operation',
    all: 'all',
    tableSize: 'density',
    default: 'default',
    large: 'large',
    small: 'small',
    fullscreen: 'full screen',
    outFullscreen: 'Exit Full Screen',
    refresh: 'refresh',
    add: 'add',
    edit: 'edit',
    del: 'del',
    type: 'type',
    title: 'title',
    enTitle: 'English title'
  },
  status: {
    normal: 'normal',
    lock: 'lock',
    deleted: 'deleted',
    disable: 'disable'
  },
  page: {
    login: {
      title: 'Login',
      remember: 'Remember me',
      forgot: 'Forgot your password?',
      submit: 'Login',
      other: 'Sign in with',
      signup: 'Sign up'
    },
    register: {
      title: 'Register',
      submit: 'Register',
      other: 'Already have an account?'
    },
    exception: {
      e403: 'Sorry, you don\'t have access to this page.',
      e404: 'Sorry, the page you visited does not exist.',
      e500: 'Sorry, the server is reporting an error.',
      btn: 'Back to home'
    },
    i18n: {
      content: 'Hello, nice to meet you!'
    },
    system: {
      user: {
        setRole: 'Set role',
        tip: {
          handleSetRole: 'Please select the row where you want to set the role',
          resetPassword: 'Please select the row where you want to reset the password',
          restPasswordConfirm: 'Are you sure you want to reset the password for user: {username}?',
          resetPasswordSuccess: 'Password reset successfully',
          newPassword: 'new password:'
        }
      },
      resource: {
        add: 'Add menu',
        allZk: 'Expand all',
        allSq: 'Collapse all',
        searchTip: 'Enter the menu name to search',
        addChild: 'Add submenu',
        editChildTip: 'After selecting an item from the menu list, edit it',
        menu: 'menu',
        button: 'button',
        titleTip: 'Please enter a title, required',
        enTitleTip: 'Please enter an English title',
        codeTip: 'Please enter code, required',
        path: 'path',
        method: 'method',
        pathTip: 'Please enter the path, required',
        buttonType: 'Button type',
        buttonSize: 'Button size',
        sort: 'sort',
        sortTip: 'Please enter the sort order, the lower the number, the higher the front',
        icon: 'icon',
        iconTip: 'Please select an icon',
        save: 'Save changes',
        closeTip: 'Close confirmation',
        closeTip1: 'You have edited the menu information, do you want to close it directly?',
        delTip: 'Delete confirmation',
        delTip1: 'Are you sure you want to delete the menu <strong>{title}</strong>?'
      },
      role: {
        buttonResource: 'Resource permissions',
        resourceTip: 'Edit resource permissions',
        buttonDataAuth: 'Data permission'
      },
      tenant: {
        defaultUserTip: 'After the tenant is created, the system will automatically assign a user. Default username: current tenant code, default password: Password123'
      }
    }
  },
  router: {
    setting: {
      center: 'Personal center',
      setting: 'Personal settings'
    },
    system: {
      user: 'Users',
      resource: 'Resources',
      role: 'Roles',
      emailHistory: 'Mail record',
      emailTemplate: 'Mail template',
      baseDict: 'System dictionary',
      tenant: 'Multi-tenant'
    }
  }
};