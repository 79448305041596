import "core-js/modules/es6.array.copy-within";
import "core-js/modules/es6.array.fill";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.array.find-index";
import "core-js/modules/es6.array.from";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.array.of";
import "core-js/modules/es6.array.sort";
import "core-js/modules/es6.array.species";
import "core-js/modules/es6.date.to-primitive";
import "core-js/modules/es6.function.has-instance";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.map";
import "core-js/modules/es6.math.acosh";
import "core-js/modules/es6.math.asinh";
import "core-js/modules/es6.math.atanh";
import "core-js/modules/es6.math.cbrt";
import "core-js/modules/es6.math.clz32";
import "core-js/modules/es6.math.cosh";
import "core-js/modules/es6.math.expm1";
import "core-js/modules/es6.math.fround";
import "core-js/modules/es6.math.hypot";
import "core-js/modules/es6.math.imul";
import "core-js/modules/es6.math.log1p";
import "core-js/modules/es6.math.log10";
import "core-js/modules/es6.math.log2";
import "core-js/modules/es6.math.sign";
import "core-js/modules/es6.math.sinh";
import "core-js/modules/es6.math.tanh";
import "core-js/modules/es6.math.trunc";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.number.epsilon";
import "core-js/modules/es6.number.is-finite";
import "core-js/modules/es6.number.is-integer";
import "core-js/modules/es6.number.is-nan";
import "core-js/modules/es6.number.is-safe-integer";
import "core-js/modules/es6.number.max-safe-integer";
import "core-js/modules/es6.number.min-safe-integer";
import "core-js/modules/es6.number.parse-float";
import "core-js/modules/es6.number.parse-int";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es7.object.define-getter";
import "core-js/modules/es7.object.define-setter";
import "core-js/modules/es7.object.entries";
import "core-js/modules/es6.object.freeze";
import "core-js/modules/es6.object.get-own-property-descriptor";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.get-own-property-names";
import "core-js/modules/es6.object.get-prototype-of";
import "core-js/modules/es7.object.lookup-getter";
import "core-js/modules/es7.object.lookup-setter";
import "core-js/modules/es6.object.prevent-extensions";
import "core-js/modules/es6.object.is";
import "core-js/modules/es6.object.is-frozen";
import "core-js/modules/es6.object.is-sealed";
import "core-js/modules/es6.object.is-extensible";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.seal";
import "core-js/modules/es6.object.set-prototype-of";
import "core-js/modules/es7.object.values";
import "core-js/modules/es6.promise";
import "core-js/modules/es7.promise.finally";
import "core-js/modules/es6.reflect.apply";
import "core-js/modules/es6.reflect.construct";
import "core-js/modules/es6.reflect.define-property";
import "core-js/modules/es6.reflect.delete-property";
import "core-js/modules/es6.reflect.get";
import "core-js/modules/es6.reflect.get-own-property-descriptor";
import "core-js/modules/es6.reflect.get-prototype-of";
import "core-js/modules/es6.reflect.has";
import "core-js/modules/es6.reflect.is-extensible";
import "core-js/modules/es6.reflect.own-keys";
import "core-js/modules/es6.reflect.prevent-extensions";
import "core-js/modules/es6.reflect.set";
import "core-js/modules/es6.reflect.set-prototype-of";
import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.flags";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.set";
import "core-js/modules/es6.symbol";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.string.anchor";
import "core-js/modules/es6.string.big";
import "core-js/modules/es6.string.blink";
import "core-js/modules/es6.string.bold";
import "core-js/modules/es6.string.code-point-at";
import "core-js/modules/es6.string.ends-with";
import "core-js/modules/es6.string.fixed";
import "core-js/modules/es6.string.fontcolor";
import "core-js/modules/es6.string.fontsize";
import "core-js/modules/es6.string.from-code-point";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.string.italics";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.string.link";
import "core-js/modules/es7.string.pad-start";
import "core-js/modules/es7.string.pad-end";
import "core-js/modules/es6.string.raw";
import "core-js/modules/es6.string.repeat";
import "core-js/modules/es6.string.small";
import "core-js/modules/es6.string.starts-with";
import "core-js/modules/es6.string.strike";
import "core-js/modules/es6.string.sub";
import "core-js/modules/es6.string.sup";
import "core-js/modules/es6.typed.array-buffer";
import "core-js/modules/es6.typed.int8-array";
import "core-js/modules/es6.typed.uint8-array";
import "core-js/modules/es6.typed.uint8-clamped-array";
import "core-js/modules/es6.typed.int16-array";
import "core-js/modules/es6.typed.uint16-array";
import "core-js/modules/es6.typed.int32-array";
import "core-js/modules/es6.typed.uint32-array";
import "core-js/modules/es6.typed.float32-array";
import "core-js/modules/es6.typed.float64-array";
import "core-js/modules/es6.weak-map";
import "core-js/modules/es6.weak-set";
import "core-js/modules/web.timers";
import "core-js/modules/web.immediate";
import "core-js/modules/web.dom.iterable";
import "regenerator-runtime/runtime";
// Vue
import Vue from 'vue';
import App from './App'; // 配置

import Setting from './setting'; // 混合

import mixinApp from '@/mixins/app'; // 插件

import plugins from '@/plugins'; // store

import store from '@/store/index'; // iView 和 iView Pro

import ViewUI from 'view-design';
import iViewPro from '@/libs/iview-pro/iview-pro.min.js';
import '@/libs/iview-pro/icon-select.css';
import iIconSelect from '@/libs/iview-pro/icon-select.min';
Vue.use(iIconSelect); // import hmWebBase from 'hm-web-base'
// Vue.use(hmWebBase);

import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
}); // 菜单和路由

import router from './router';
import menuHeader from '@/menu/header';
import menuSider from '@/menu/sider';
import { frameInRoutes } from '@/router/routes'; // 多语言

import _i18n from '@/i18n'; // 方法

import { getHeaderName, getMenuSider, getSiderSubmenu } from '@/libs/system';
import iRegion from '@/components/region/region.min';
Vue.use(iRegion); // 内置组件

import iLink from '@/components/link';
import iFrame from '@/components/frame'; // 使用样式，修改主题可以在 styles 目录下创建新的主题包并修改 iView 默认的 less 变量
// 参考 https://www.iviewui.com/docs/guide/theme

import './styles/index.less';
import './libs/iview-pro/iview-pro.css';
import tableImportExport from '@/libs/iview-pro/table-import-export.min';
if (window) window.$t = function (key, value) {
  return _i18n.t(key, value);
};
Vue.use(plugins);
Vue.use(tableImportExport);
Vue.use(ViewUI, {
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
});
Vue.use(iViewPro);
Vue.component('i-link', iLink);
Vue.component('i-frame', iFrame);
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import util from '@/libs/util';
Vue.prototype.$util = util;
import qs from 'qs';
Vue.prototype.$qs = qs;
Vue.prototype.$customLogo = 'https://wx-center.oss-cn-shanghai.aliyuncs.com/custom-logo.png';
import duoImageViewer from 'duo-image-viewer';
Vue.use(duoImageViewer);
Vue.filter('dateFormat', function (originVal) {
  if (originVal == null) {
    return '';
  }

  var dt = new Date(originVal);
  var y = dt.getFullYear();
  var m = (dt.getMonth() + 1 + '').padStart(2, '0');
  var d = (dt.getDate() + '').padStart(2, '0');
  var hh = (dt.getHours() + '').padStart(2, '0');
  var mm = (dt.getMinutes() + '').padStart(2, '0');
  var ss = (dt.getSeconds() + '').padStart(2, '0');
  return "".concat(y, "-").concat(m, "-").concat(d, " ").concat(hh, ":").concat(mm, ":").concat(ss);
});
Vue.filter('dateFormatDay', function (originVal) {
  if (originVal == null) {
    return '';
  }

  var dt = new Date(originVal);
  var y = dt.getFullYear();
  var m = (dt.getMonth() + 1 + '').padStart(2, '0');
  var d = (dt.getDate() + '').padStart(2, '0');
  return "".concat(y, "-").concat(m, "-").concat(d);
});
Vue.filter('userStatusFormat', function (status) {
  //格式化用户状态
  switch (status) {
    case 'NORMAL':
      return '正常';

    case 'LOCK':
      return '锁定';

    case 'DELETED':
      return '删除';

    case 'DISABLE':
      return '禁用';
  }
});
Vue.filter('interviewObjectFormat', function (status) {
  switch (status) {
    case 'boss':
      return '老板';

    case 'operator':
      return '运营';

    case 'marketing':
      return '市场推广';

    case 'adviser':
      return '咨询主管/顾问主管';

    case 'beautician':
      return '护士长/美容师主管';
  }
});
Vue.filter("positionFormat", function (position) {
  switch (position) {
    case "SALES":
      return "销售CSM";

    case "SALES_BDM":
      return "销售BDM";

    case "SALES_CES":
      return "销售CES";

    case "SALES_LEADER":
      return "销售主管";

    case "AREA_DIRECTOR":
      return "区域总监";

    case "SALES_ISM":
      return "销售ISM";

    case "DIRECTOR OF SALES":
      return "销售总监";

    case "IT":
      return "IT";

    case "FINANCE":
      return "财务";

    case "OPERATION":
      return "运营";

    case "DISTRIBUTOR":
      return "经销商";

    case "LEGAL":
      return "法务";

    case "VVIP":
      return "VVIP报告";

    case "AFTER_SALES":
      return "售后客服";
  }
});
Vue.filter("contractFileFormat", function (status) {
  switch (status) {
    case "HISTORY_CONFIRM":
      return "历史确认";

    case "CURRENT_CONFIRM":
      return "当前确认";

    case "CONFIRM":
      return "最终确认";

    case "WAIT_APPLY":
      return "待审核";

    case "APPLY_NOT_PASS":
      return "审核未通过";

    case "SEAL_NOT_PASS":
      return "用印未通过";

    case "CONTRACT_CANCEL":
      return "已作废";

    case "SEAL_CANCEL":
      return "已作废";

    case "SEAL_SINGLE":
      return "合同用印（单方）";

    case "SEAL_DOUBLE":
      return "合同用印（双方）";

    case "FINALLY":
      return "最终版本";
  }
});
Vue.filter("letterStatusFormat", function (status, firstSeal) {
  switch (status) {
    case "WAIT_SUBMIT":
      return "待提交";

    case "WAIT_APPLY":
      return "待审核";

    case "APPLY_PASS":
      return "审核通过";

    case "APPLY_NOT_PASS":
      return "审核未通过";

    case "WAIT_CONTRACT_CONFIRM":
      return "待合同确认";

    case "CONTRACT_EDIT_WAIT":
      return "合同修改(未上传)";

    case "CONTRACT_EDIT_OVER":
      return "合同修改(已上传)";

    case "WAIT_SEAL_SINGLE":
      if (firstSeal == 'CUSTOM') {
        return '待单方用印(客户)';
      }

      return "待单方用印(海菲秀)";

    case "WAIT_SEAL_DOUBLE":
      if (firstSeal == 'CUSTOM') {
        return '待合同用印_双方(海菲秀)';
      }

      return "待合同用印_双方(客户)";

    case "WAIT_CONTRACT_FINAL":
      return "待合同终审";

    case "HALF_FINAL":
      return "合同半终审";

    case "ORDER_CREATE":
      return "订单已生成";

    case "LETTER_CANCEL":
      return "已取消";

    case "WAIT_APPLY_CANCEL":
      return "待审核取消";
  }
});
Vue.filter("contractStatus", function (status) {
  switch (status) {
    case "MASTER":
      return "主合同";

    case "SUPPLEMENT":
      return "补充合同";
  }
});
Vue.filter("orderStatusFormat", function (status) {
  switch (status) {
    case "WAIT_APPLY":
      return "待审核";

    case "APPLY_NOT_PASS":
      return "审核未通过";

    case "WAIT_PAY":
      return "待支付";

    case "WAIT_PAY_OVER":
      return "待结清";

    case "WAIT_RECEIVED":
      return "已发货";

    case "IN_DELIVERY":
      return "配送中";

    case "DOWN":
      return "已完成";

    case "WAIT_SEND":
      return "待发货";

    case "CANCELED":
      return "已取消";

    case "RECEIVED":
      return "已收货";

    case "WAIT_APPLY_CANCEL":
      return "待审核取消";

    case "WAIT_CONTRACT":
      return "待合同上传";

    case "FAIL_CONTRACT":
      return "合同审核失败";
  }
});
new Vue({
  mixins: [mixinApp],
  router: router,
  store: store,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  },
  created: function created() {
    // 处理路由 得到每一级的路由设置
    this.$store.commit('admin/page/init', frameInRoutes); // 设置顶栏菜单

    this.$store.commit('admin/menu/setHeader', menuHeader); // 加载用户登录的数据

    this.$store.dispatch('admin/account/load'); // 初始化全屏监听

    this.$store.dispatch('admin/layout/listenFullscreen');
  },
  watch: {
    // 监听路由 控制侧边栏显示 标记当前顶栏菜单（如需要）
    '$route': function $route(to, from) {
      var path = to.matched[to.matched.length - 1].path;
      var menus = this.$store.state.admin.menu.sider;
      var openNames = getSiderSubmenu(path, menus);
      this.$store.commit('admin/menu/setActivePath', to.path);
      this.$store.commit('admin/menu/setOpenNames', openNames);
      this.appRouteChange(to, from);
    }
  }
}).$mount('#app');